import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import twitter from "../images/twitter.png"
import instagram from "../images/instagram.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/*<div class="nav_bar">
      <Link to="" activeClassName="active">About</Link>
      <Link to="/quora/">Work</Link>
    </div>*/} 
    <div class="name_title">kristina varshavskaya</div>
    <div class="hero"></div>
    <div class="bio">
      <p>
        Hello, I'm Kristina.
        I'm an independent designer that specializes in social systems design and growth.
        </p>
        <p>
        Previously, I was a lead designer at Quora focusing on growth efforts, the native app design system, and writing tools. Before that, 
        I worked at Facebook designing the Facebook camera and creative tools. 
        I started my career in design when I dropped out of high school in 2011 to join Wanelo as the first employee and first designer. 
        I most recently lived in San Francisco for 8 years, and now you can find me traveling somewhere. I was born in a place you may recognize as a Siberian territory in the board game Risk, or as <a href="https://www.nationalgeographic.com/photography/proof/2018/01/coldest-city-siberia/" target="_blank">the coldest city in the world</a>.
      </p>
      <p>
      I enjoy teaching design, and since 2017 have been teaching interaction design and mobile prototyping at the <a href="http://ciid.dk/education/visiting-faculty/full-faculty-listing/kristina-varshavskaya/" target="_blank">Copenhagen Institute for Interaction Design</a>. I've also taught mobile interaction design at York University. 
      </p>
      <p>
      I'm currently taking on projects for 2020. If you are looking to turn an idea into a beautiful, usable product or scale your user base through A/B tested product development, let's chat. Get in touch: <a href="mailto:kristinavarsh@gmail.com" class="email">kristinavarsh@gmail.com</a>
      </p>
    </div>
    <div class="social_links">
      <a href="https://www.twitter.com/kristinatastic" target="_blank" class="twitter">
        <img src={twitter} class="social_img" />
        <span>@kristinatastic</span>
      </a>
      <a href="https://www.instagram.com/kristinatastic_" target="_blank" class="instagram">
        <img src={instagram} class="social_img" />
        <span>@kristinatastic_</span>
      </a>
      
    </div>
  </Layout>
)

export default IndexPage
